<template>
  <el-dialog :visible.sync="dialog" :title="isAdd ? '新增用户' : '编辑用户'" append-to-body width="700px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="100px">
      <el-form-item label="所属站点" prop="siteId">
        <el-select v-model="form.siteId" filterable placeholder="请选择" style="width: 300px;">
          <el-option
            v-for="item in sites"
            :key="item.id"
            :value="item.id"
            :label="item.siteName"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户名" prop="username">
        <el-input v-model="form.username" style="width: 370px;" :disabled="!isAdd"/>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="form.email" style="width: 370px;" />
      </el-form-item>
      <el-form-item label="状态" prop="enabled">
        <el-radio v-model="form.enabled" label="true">激活</el-radio>
        <el-radio v-model="form.enabled" label="false">锁定</el-radio>
      </el-form-item>
      <el-form-item style="margin-bottom: 0px;" label="角色">
        <treeselect
          v-model="roleIds"
          :multiple="true"
          :options="roles"
          style="width: 370px;"
          placeholder="请选择角色"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 0px;margin-top: 10px;" label="数据权限">
        <el-card class="box-card" shadow="never">
          <!--<div slot="header" class="clearfix">
              <span class="role-span">数据权限</span>
               <el-button
                v-if="showButton && checkPermission(['ADMIN','ROLES_ALL','ROLES_EDIT'])"
                :loading="menuLoading"
                icon="el-icon-check"
                size="mini"
                style="float: right; padding: 4px 10px"
                type="info"
                @click="saveMenu">保存</el-button>
          </div>-->
          <div :style="'min-height: 207px;max-height:' + height + 'overflow-y: auto;'">
            <el-tree
              ref="dataPer"
              :data="userDataPermission"
              :default-checked-keys="dataPermissionIds"
              :props="defaultProps"
              show-checkbox
              node-key="id"
            />
          </div>
        </el-card>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from "@/api/system/user";
import {
  buildByUserName,
  savePerByUserName,
  getUserTree
} from "@/api/system/permission";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapGetters } from "vuex";
import { strongUserName,getByteLen} from '@/utils/validate'
export default {
  name: "Form",
  components: { Treeselect },
  props: {
    roles: {
      type: Array,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },

    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
  },
  computed: {
    ...mapGetters(["name"])
  },
  data() {
    let validatetUsername = (rule,value,callback) => {
        if(!value){
          callback(new Error('请输入用户名'));
        }
        if(this.form.id>0){
          callback();
        } else if (getByteLen(value)>20||getByteLen(value)<3){
          callback(new Error('用户名长度在 3 到 20 个字符(10个汉字)！'));
        }else if(!strongUserName(value)) {
          callback(new Error('用户名只能是字母、数字和下划线的组合'));
        }else {
          callback();
        }

      };
    return {
      defaultProps: {
        children: "children",
        label: "dimensionName"
      },
      dataPermission: [],
      userDataPermission: [],
      dataPermissionIds: [],
      height: document.documentElement.clientHeight - 94.5 - 260 + "px;",
      dialog: false,
      loading: false,
      form: {
        username: "",
        email: "",
        enabled: "false",
        roles: [],
        siteId: ''
      },
      roleIds: [],
      rules: {
        username: [
          { validator: validatetUsername, trigger: 'blur' }
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" }
        ],
        enabled: [{ required: true, message: "状态不能为空", trigger: "blur" }],
        siteId: [
          { required: true, message: "所属站点不能为空", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    getUserPermission(userName) {

      let params = { userName: userName };
      buildByUserName(params)
        .then(res => {
          // 处理权限数据
          this.dataPermissionIds = [];
          for (var i = 0; i < res.length; i++) {
            this.dataPermissionIds.push(res[i].dimensionTreeId);
          }
          this.$refs.dataPer.setCheckedKeys(this.dataPermissionIds)
        })
        .catch(err => {});
    },

    savePerByUserName() {
      const role = {
        userName: this.form.username,
        treeIds: this.$refs.dataPer.getCheckedKeys()
      };
      savePerByUserName(role)
        .then(res => {
          this.$emit('refresh-userlist');
          this.resetForm();
          this.$notify({
            title: this.isAdd ? "添加成功" : "修改成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
        }).catch(err => {
          this.loading = false
          this.resetForm()
        })
    },
    cancel() {
      this.resetForm();
    },
    doSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.form.roles = [];
          const _this = this;
          this.roleIds.forEach(function(data, index) {
            const role = { id: data };
            _this.form.roles.push(role);
          });
          if (this.isAdd) {
            console.log('aaa');
            this.doAdd();
          } else this.doEdit();
        } else {
          return false;
        }
      });
    },
    doAdd() {
      let copyForm = JSON.parse(JSON.stringify(this.form));
      copyForm.srSite = {
        id: copyForm.siteId
      };
      delete copyForm.siteId;
      add(copyForm).then(res => {
          this.savePerByUserName();
        }).catch(err => {
          this.loading = false
          this.resetForm()
        })
    },
    doEdit() {
      let copyForm = JSON.parse(JSON.stringify(this.form));
      copyForm.srSite = {
        id: copyForm.siteId
      };
      delete copyForm.siteId;
      edit(copyForm)
        .then(res => {
          this.savePerByUserName();
        }).catch(err => {
          this.loading = false
          this.resetForm()
        })
    },
    saveMenu() {
      const role = { id: this.currentId, menus: [] };
      // 得到半选的父节点数据，保存起来
      this.$refs.menu.getHalfCheckedNodes().forEach(function(data, index) {
        const permission = { id: data.id };
        role.menus.push(permission);
      });
      // 得到已选中的 key 值
      this.$refs.menu.getCheckedKeys().forEach(function(data, index) {
        const permission = { id: data };
        role.menus.push(permission);
      });
      editMenu(role)
        .then(res => {
          this.$notify({
            title: "保存成功",
            type: "success",
            duration: 2500
          });
          this.update();
        })
    },
    resetForm() {
      this.dialog = false;
      this.$refs["form"].resetFields();
      this.roleIds = [];
      this.form = { username: "", email: "", enabled: "false", roles: [] };
      this.dataPermissionIds = [];
    },
  }
};
</script>

<style scoped>
</style>
