<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm
      ref="form"
      :roles="roles"
      :sites="sites"
      :sup_this="sup_this"
      :is-add="false"
      @refresh-userlist="$emit('refresh-userlist')"
    />
  </div>
</template>
<script>
import eForm from "./form";
import { mapGetters } from 'vuex'
export default {
  components: { eForm },
  props: {
    roles: {
      type: Array,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    // index.vue 的this 可用于刷新数据
    sup_this: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['userDataPermission'])
  },
  methods: {
    to() {
      const _this = this.$refs.form;
      _this.roleIds = [];
      _this.form = {
        id: this.data.id,
        siteId: this.data.srSite.id,
        /*srSite: {
          id: this.data.srSite.id
        },*/
        // siteId: this.data.siteId,
        username: this.data.username,
        email: this.data.email,
        enabled: this.data.enabled.toString(),
        roles: []
      };
      this.data.roles.forEach(function(data, index) {
        _this.roleIds.push(data.id);
      });
      _this.getUserPermission(this.data.username);
      _this.userDataPermission = this.userDataPermission;
      _this.dialog = true;
    }
  }
};
</script>

<style scoped>
div {
  display: inline-block;
  margin-right: 3px;
}
</style>
